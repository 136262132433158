<template>
  <validation-observer
    ref="changeRecoverDataPasswordFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="changeRecoverDataPasswordModal"
      centered
      title="Đổi mật khẩu phục hồi dữ liệu"
      body-class="position-static"
      :no-close-on-backdrop="true"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="oldPassword">
          <template v-slot:label>
            Mật khẩu cũ <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Mật khẩu cũ"
            rules="required"
          >
            <b-form-input
              id="oldPassword"
              v-model="oldPassword"
              type="password"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="newPassword">
          <template v-slot:label>
            Mật khẩu mới <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Mật khẩu mới"
            vid="password"
            rules="required|min:8|password"
          >
            <b-form-input
              id="newPassword"
              v-model="newPassword"
              type="password"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="confirmPassword">
          <template v-slot:label>
            Nhập lại mật khẩu mới <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Nhập lại mật khẩu mới"
            rules="required|confirmed:password"
          >
            <b-form-input
              id="confirmPassword"
              v-model="confirmPassword"
              type="password"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mr-1"
            @click="onRefresh"
          >
            <span class="text-right">
              <feather-icon icon="RefreshCcwIcon" /> Reset lại mật khẩu
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('changeRecoverDataPasswordModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>

      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BModal, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import { getUser } from '@/auth/utils'

export default {
  name: 'changeRecoverDataPassword',
  directives: {
    Ripple,
  },
  components: {
    BFormInput,
    BOverlay,
    BModal,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    }
  },
  methods: {
    ...mapActions({
      changeRecoverDataPassword: 'scoreLockPassword/changeRecoverDataPassword',
      resetRecoverDataPassword: 'scoreLockPassword/resetRecoverDataPassword',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onHide() {
      this.$refs
        .changeRecoverDataPasswordFormRef
        .reset()
      this.oldPassword = ''
      this.newPassword = ''
      this.confirmPassword = ''
    },
    onRefresh() {
      this.$swal({
        title: `Bạn chắc chắn muốn tạo lại mật khẩu cho <span class="text-danger">${this.user.username}</span>?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.isLoading = true
            try {
              const response = await this.resetRecoverDataPassword(this.user.accountId)
              if (response) {
                const { isSuccessful, message } = response
                if (isSuccessful) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            } catch (e) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `[${e.code}] ${e.message}`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            } finally {
              this.isLoading = false
            }
          }
        })
    },
    async onSave() {
      const valid = this.$refs
        .changeRecoverDataPasswordFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.changeRecoverDataPassword({
            id: this.user.accountId,
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          })
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('changeRecoverDataPasswordModal')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
